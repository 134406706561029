import Footer from "@/components/Footer/Footer";
import Header from "@/components/Header/Header";
import Section from "@/components/Section/Section";
import * as React from "react";
import CookieCompliance from "@/components/CookieCompliance/CookieCompliance";
import {
  deleteCookiesConsent,
  getIsCookieConsent,
} from "../components/CookieConsent/CookieConsent";

const Contact = () => {
  const [formSent, setFormSent] = React.useState(false);
  const [formError, setFormError] = React.useState(false);

  const sendData = async (
    e: React.FormEvent<HTMLFormElement>,
    token: string,
  ) => {
    const target = e.target as any;
    const data = {
      name: target.name.value,
      email: target.email.value,
      message: target.message.value,
      captchaToken: token,
    };

    const response = await fetch(`${global.api_url}/v1/web/contact`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: { "Content-Type": "application/json" },
    });

    target.reset();
    if (!response.ok) setFormError(true);
    setFormSent(true);
  };

  const initRecaptcha = () => {
    if (!getIsCookieConsent("gatsby-gdpr-google-recaptcha")) return;

    try {
      grecaptcha.ready(() => {
        grecaptcha.render("captcha-container", {
          sitekey: "6LehGt4fAAAAAKkIHu0RcF4YScFigj2lnLfZpYlU",
        });
      });
    } catch (e) {
      // we are loading recaptcha after cookie consent, so it might not be ready yet
      setTimeout(() => {
        initRecaptcha();
      }, 150);
    }
  };

  React.useEffect(() => {
    initRecaptcha();
  }, []);

  return (
    <main>
      <CookieCompliance />
      <Header></Header>

      <Section className="hero centered Contact__headline">
        <div className="hero__headline">
          <h1>Contact us!</h1>
          <p className="testimonial__logos__description">
            Do you have any suggestions on what to improve on ecomtrack? We
            would love to hear from you. Do not hesitate and fill the form down
            below.
          </p>
        </div>

        {!getIsCookieConsent("gatsby-gdpr-google-recaptcha") ? (
          <p
            style={{
              color: "white",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={deleteCookiesConsent}
          >
            Enable Cookies to Use the Contact Form
          </p>
        ) : (
          <form
            className="Contact__form"
            method="post"
            id="contact-form"
            onSubmit={e => {
              e.preventDefault();
              const token = grecaptcha.getResponse();
              sendData(e, token);
            }}
          >
            <div className="Contact__form__row">
              <label>
                Name
                <input
                  placeholder="John Doe"
                  type="text"
                  name="name"
                  id="name"
                />
              </label>
              <label>
                Email
                <input
                  placeholder="john.doe@mail.com"
                  type="email"
                  name="email"
                  id="email"
                />
              </label>
            </div>

            <label htmlFor="message" className="label__textarea">
              Message
            </label>

            <textarea
              placeholder="your message"
              name="message"
              id="message"
              rows={10}
            />

            <div id="captcha-container" style={{ marginTop: 20 }}></div>

            <button type="submit" className="Button--regular Button__blue">
              Send message
            </button>
          </form>
        )}
      </Section>
      <Section className="centered">
        {formSent && (
          <div className="wrap">
            <p className="center">
              {formError ? (
                <>Something went wrong. Please try again later</>
              ) : (
                <>Thank you for letting us know. 👋</>
              )}
            </p>
          </div>
        )}
      </Section>

      <Footer colFlex></Footer>
    </main>
  );
};
export default Contact;
